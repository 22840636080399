<template>
 
<div class="content-box">
  <div class="container">

    <el-breadcrumb separator="/">
      <el-breadcrumb-item>协同管理</el-breadcrumb-item>
      <el-breadcrumb-item>协同服务</el-breadcrumb-item>
      <el-breadcrumb-item>制造业论坛</el-breadcrumb-item>
    </el-breadcrumb>

    <div>
      <!-- <iframe class="content" src="https://meeting.tencent.com/" frameborder="0"></iframe> -->
      <iframe class="content" src=" http://www.aii-alliance.org/ " frameborder="0"></iframe>

      <!-- <hr> -->
      <!-- <iframe :src= url width="1000" height="500" frameborder="0" scrolling="auto"></iframe> -->
    </div>
  </div>
    </div>
</template>

<script>
import { fileList, fileRemove } from '@/api/file'


export default {
  name: 'luntan',
  components: {  },
  data () {
    return {
      dialogVisible: false,
      tableData: [],
      url:"http://vuex.vuejs.org/"
    }
  },
  computed: {
    baseApi () {
      return process.env.VUE_APP_BASE_API
    }
  },
  created () {
    // this.getFileList()
    // this.list()
  },
  methods: {
    list(){
    var url='https://meeting.tencent.com/user-center/join';//跨域url   
    // var param = 'https://meeting.tencent.com/user-center/join'  
    var ifr =document.createElement("iframe");//以下创建一个隐藏的iframe   
    ifr.setAttribute("width",0);   
    ifr.setAttribute("height",0);   
    document.body.appendChild(ifr);   
    ifr.src=encodeURI(url);   
    },
  },
}
</script>


<style lang="scss" scoped>
.button-gutter {
  margin: 20px 0;
}
.content{
  margin-top: 20px;
  width: 80vw;
  height: 83vh;
}
</style>